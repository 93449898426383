<!-- 
  /*!
 * @ngx-dummy/Accordion-Simple library
 * Simple accordion created for angular / ionic projects.
 * https://github.com/ngx-dummy/accordion-simple
 *
 * Copyright  Vladimir Ovsyukov <ovsyukov@yandex.com>
 * Published under GNU GPLv3 License
 */
 -->
<div class="mdc-layout-grid">
	<div class="mdc-layout-grid__inner mb-1">
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet align">
			<h2 class="text-center app-title">{{ title }}</h2>
		</div>
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet align">
			<div class="mdc-touch-target-wrapper">
				<button class="mdc-button mdc-button--touch light-bg" (click)="acc1.closeAll(); acc2.closeAll()">
					<div class="mdc-button__ripple"></div>
					<span>Close All</span>
				</button>
			</div>
		</div>
	</div>
	<div class="mdc-layout-grid__inner mb-1">
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
			<ngxd-accordion
				#acc1="ngxdAccordion"
				[accordionList]="accordList2"
				[accordionStyling]="styling"
				openSign="plus.png"
				closeSign="minus.png"
			></ngxd-accordion>
		</div>
	</div>
	<div class="mdc-layout-grid__inner">
		<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
			<ngxd-accordion
				#acc2="ngxdAccordion"
				[loadingTpl]="simpleLoader"
				[accordionList]="accordList$ | async"
				[accordionStyling]="styling1"
				listLogo="list_white_48dp.png"
			></ngxd-accordion>
		</div>
	</div>
</div>

<ng-template #simpleLoader>
	<style>
		:host {
			--spin-bg: #46cccc;
			--spin-in-bg: #4adada;
		}

		.container-transparent {
			background: transparent;
			display: grid;
			min-height: 6rem;
			width: 100%;
			place-content: center;
		}

		.loader {
			width: 3rem;
			height: 3rem;
			border: 0.3rem solid var(--spin-bg);
			animation: spin 2s infinite ease-in-out;
		}

		.loader-inner {
			vertical-align: top;
			display: inline-block;
			width: 100%;
			background-color: var(--spin-in-bg);
			animation: spin-in 2s infinite ease-in;
		}

		@keyframes spin {
			0% {
				opacity: 0.4;
				transform: rotate(0deg);
			}

			25% {
				opacity: 0.6;
				transform: rotate(180deg);
			}

			50% {
				opacity: 0.9;
				transform: rotate(180deg);
			}

			75% {
				opacity: 1;
				transform: rotate(390deg);
			}

			100% {
				opacity: 0.9;
				transform: rotate(360deg);
			}
		}

		@keyframes spin-in {
			0% {
				opacity: 0.4;
				height: 0%;
			}

			25% {
				opacity: 0.6;
				height: 0%;
			}

			50% {
				opacity: 0.9;
				height: 100%;
			}

			75% {
				opacity: 1;
				height: 100%;
			}

			100% {
				opacity: 0.9;
				height: 0%;
			}
		}
	</style>
	<div class="container-transparent" @moveIn>
		<span class="loader"><span class="loader-inner"></span></span>
	</div>
</ng-template>

<ng-template #simpleBodyTmpl let-ctxcontent>
	<style>
		.tmpl-item-container {
			font-family: 'Roboto', sans-serif !important;
		}

		.head6 {
			font-style: italic;
			margin: 1.1rem;
		}

		.subtitle1 {
			color: var(--mdc-theme-text-primary-on-dark, rgba(250, 250, 250, 0.9));
			text-align: start;
			margin: 0.1rem 1rem;
			font-family: Lato;
		}

		.subtitle2 {
			color: var(--mdc-theme-text-primary-on-light, rgba(250, 250, 250, 0.9));
			text-align: start;
			margin: 0.1rem 1rem;
		}

		.card-media__items {
			position: absolute;
			bottom: 1rem;
			left: 0.4rem;
		}

		.tmpl-item-logo {
			background-image: url('myTwitLogo.png');
		}

		.tmpl-main-content {
			background-color: floralwhite;
			font-size: medium;
		}

		.body2 {
			margin: 0.4rem 1rem;
		}

		.tmpl-footer {
			background-color: lavenderblush;
			color: black;
			font-size: xx-small;
		}

		@media screen and (max-width: 500px) {
			.head6 {
				font-size: 1rem;
				margin: 0.1rem;
			}

			.subtitle1 {
				font-size: 0.4rem;
			}

			.subtitle2 {
				font-size: 0.2rem;
			}

			.flex-card__tmpl {
				display: grid;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap !important;
			}
		}
	</style>
	<ng-container ngProjectAs="tmpl-item-card__container">
		<div class="mdc-card tmpl-item-container">
			<div class="tmpl-header">
				<h6 class="mdc-typography mdc-typography--headline6 head6">Custom templated Item sample</h6>
			</div>
			<div class="mdc-card__primary-action" tabindex="0">
				<div
					class="mdc-card__media mdc-card__media--16-9 demo-card__media tmpl-item-logo"
					onclick="location.href='//twitter.com/intent/tweet?ref_src=twsrc%5Etfw&original_referer=https%3A%2F%2Fngx-dummy.github.io%2Faccordion-simple%2F&url=https%3A%2F%2Fgithub.com%2Fvovansuper%2Faccordion-simple&text=ngx-dummy Accordion simple component'"
				>
					<div class="mdc-card__media-content demo-card__media-content">
						<div class="card-media__items">
							<h3 class="mdc-typography mdc-typography--subtitle1 subtitle1">by Vladimir Ovsyukov</h3>
							<h6 class="mdc-typography mdc-typography--subtitle2 subtitle1">https://github.com/VovanSuper</h6>
						</div>
					</div>
				</div>
				<div class="mdc-typography mdc-typography--body2 mt-1 mb-1 body2" [innerHTML]="ctxcontent"></div>
			</div>
			<div class="mdc-card__actions flex-card__tmpl">
				<div class="mdc-card__action-buttons">
					<button class="mdc-button mdc-card__action mdc-card__action--button" onclick="location.href='//github.com/ngx-dummy/accordion-simple'">
						<span class="mdc-button__ripple"></span>
						<span class="material-icons">description</span>
						Docs
					</button>
					<button
						class="mdc-button mdc-card__action mdc-card__action--button"
						onclick="location.href='//github.com/VovanSuper' "
						aria-label="Follow VovanSuper on GitHub"
					>
						<span class="mdc-button__ripple"></span>
						<span class="material-icons">thumb_up_alt</span>
						Follow
					</button>
				</div>
				<div class="mdc-card__action-icons">
					<button
						class="mdc-icon-button mdc-card__action mdc-card__action--icon--unbounded"
						aria-pressed="false"
						aria-label="Sponsor"
						title="Sponsor"
						onclick="location.href='//github.com/sponsors/ngx-dummy'"
						data-icon="octicon-heart"
						aria-label="Sponsor Me on GitHub"
					>
						<i class="material-icons mdc-icon-button__icon mdc-icon-button__icon--on">favorite</i>
					</button>
					<button
						class="mdc-icon-button material-icons mdc-card__action mdc-card__action--icon--unbounded"
						title="Share"
						data-mdc-ripple-is-unbounded="true"
						onclick="location.href='//twitter.com/intent/tweet?ref_src=twsrc%5Etfw&original_referer=https%3A%2F%2Fngx-dummy.github.io%2Faccordion-simple%2F&url=https%3A%2F%2Fgithub.com%2Fvovansuper%2Faccordion-simple&text=ngx-dummy Accordion simple component'"
					>
						share
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #tmpl2 let-ctxcontent>
	<style scoped>
		.row {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
		.row .col {
			float: left;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			padding: 0 0.75rem;
			min-height: 1px;
		}
		.row .col.s12 {
			width: 100%;
			margin-left: auto;
			left: auto;
			right: auto;
		}
		.row:after {
			content: '';
			display: table;
			clear: both;
		}
		.card {
			position: relative;
			margin: 0.5rem 0 1rem 0;
			background-color: #fff;
			-webkit-transition: -webkit-box-shadow 0.25s;
			transition: -webkit-box-shadow 0.25s;
			transition: box-shadow 0.25s;
			transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
			border-radius: 2px;
		}
		.card.large .card-image {
			max-height: 60%;
			position: relative;
			overflow: hidden;
		}
		.card .card-image img {
			display: block;
			border-radius: 2px 2px 0 0;
			position: relative;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
		}
		.card .card-image .card-title {
			font-size: 24px;
			font-weight: 300;
			color: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			max-width: 100%;
			padding: 24px;
		}
		.btn-floating.halfway-fab {
			position: absolute;
			right: 24px;
			bottom: -20px;
		}
		.waves-effect {
			position: relative;
			cursor: pointer;
			display: inline-block;
			overflow: hidden;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			vertical-align: middle;
			z-index: 1;
			-webkit-transition: 0.3s ease-out;
			transition: 0.3s ease-out;
		}
		.btn-floating {
			font-size: 14px;
			outline: 0;
			display: inline-block;
			color: #fff;
			position: relative;
			overflow: hidden;
			z-index: 1;
			width: 40px;
			height: 40px;
			line-height: 40px;
			padding: 0;
			background-color: #26a69a;
			border-radius: 50%;
			-webkit-transition: background-color 0.3s;
			transition: background-color 0.3s;
			cursor: pointer;
			vertical-align: middle;
		}
		.red {
			background-color: var(--mdc-theme-on-primary, #fff);
		}
		.card .card-content {
			/* overflow: hidden; */
			max-height: 40%;
			margin: 1rem;
		}
		.material-icons {
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			line-height: 1;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;
		}
		a.btn-floating[title='Share'] {
			bottom: 0.5rem;
			right: 1rem;
			text-align: center;
			display: grid;
			place-content: center;
		}
	</style>

	<div class="container materializelikes">
		<div class="row">
			<div class="div col s12 l12 center-align" style="font-size: 1.5rem; line-height: 110%; margin: 0.1rem; color: var(--darker-color)">
				Sample Accordion Item (alike one of materializecss)
			</div>
		</div>
		<div class="row">
			<div class="col s12">
				<div class="row">
					<div class="col s12 m12">
						<div class="card large">
							<div class="card-image">
								<img src="myTwitLogo.png" />
								<span class="card-title">Simple material template Card</span>
								<a
									class="btn-floating halfway-fab waves-effect waves-light red"
									title="Share"
									data-mdc-ripple-is-unbounded="true"
									onclick="location.href='//twitter.com/intent/tweet?ref_src=twsrc%5Etfw&original_referer=https%3A%2F%2Fngx-dummy.github.io%2Faccordion-simple%2F&url=https%3A%2F%2Fgithub.com%2Fvovansuper%2Faccordion-simple&text=ngx-dummy Accordion simple component'"
								>
									<i class="material-icons">share</i>
								</a>
							</div>
							<div class="card-content">
								<p>{{ ctxcontent }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
